<template>
  <div class="map-container d-flex flex-column flex-grow-1 position-relative" style="min-height: 600px">
    <div id="map" class="w-100 d-flex flex-grow-1"></div>
  </div>
</template>
<script>
let mapInstance = null;
let clustererInstance = null;
import { MarkerClusterer, SuperClusterViewportAlgorithm } from '@googlemaps/markerclusterer';

export default {
  props: [
    'mapKey',
    'mapId',
    'canSeeChargePoints',
    'canSeeHubject',
    'userEmpId',
    'userCpoId',
    'coordinates',
    'center',
    'zoom',
    'reset',
  ],
  data: function () {
    return {
      markers: [],
      hubjectMarkers: {},
      newMarkers: null,
      map: null,
      infoWindow: null,
      zoomLevel: 8,
      geoHubjectMarkers: {},
      groupMarkers: [],
      out: true,
    };
  },
  watch: {
    coordinates: {
      handler: async function () {
        if (!this.coordinates) {
          return;
        }

        const [lat, lng] = this.coordinates.split('_');
        this.map.setCenter({ lat: +lat, lng: +lng });
        this.map.setZoom(15);

        await this.getHubjectData();
        if (this.hubjectMarkers[+lat + '_' + +lng] && this.hubjectMarkers[+lat + '_' + +lng].marker) {
          google.maps.event.trigger(this.hubjectMarkers[+lat + '_' + +lng].marker, 'click');
        }
      },
    },
    reset: {
      handler: function () {
        this.resetMap();
      },
    },
  },
  methods: {
    resetMap: function () {
      const center = this.center.split('_');
      this.map.setCenter({ lat: +center[0], lng: +center[1] });
      this.map.setZoom(this.zoom);
    },
    getData: function () {
      axios
        .get('get-map')
        .then((response) => {
          this.newMarkers = response.data.map;
          this.cleanMarkers();
          this.setMarkers();
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            this.error = error.response.data.error;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
    },
    getHubjectData: async function (status = false, ids = []) {
      return new Promise((resolve) => {
        axios
          .get('admin/hubject-emp/map-data', {
            params: {
              status: status,
              min_latitude: status ? null : mapInstance.getBounds().getSouthWest().lat(),
              min_longitude: status ? null : mapInstance.getBounds().getSouthWest().lng(),
              max_latitude: status ? null : mapInstance.getBounds().getNorthEast().lat(),
              max_longitude: status ? null : mapInstance.getBounds().getNorthEast().lng(),
              ids: ids,
            },
          })
          .then(async (response) => {
            if (status) {
              this.updateHubjectMarkers(response.data.data);
            } else {
              await this.prepareHubjectMarkers(response.data.data);
            }
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              this.error = error.response.data.error;
            } else {
              // Something happened in setting up the request that triggered an Error
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },
    getHubjectDescription: async function (id) {
      return new Promise((resolve) => {
        axios
          .get('admin/hubject-emp/map-info', {
            params: {
              id: id,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              this.error = error.response.data.error;
            } else {
              // Something happened in setting up the request that triggered an Error
            }

            resolve([]);
          });
      });
    },
    cleanMarkers: function () {
      for (const marker of Object.values(this.markers)) {
        marker.marker.position = null;
      }
      this.markers = {};
    },
    assignPin: function (status, availability, connection) {
      const sPublic = '/assets/images/cp-';
      const sPrivate = '/assets/images/cp-private-';
      const offline = sPublic + 'offline.svg';

      const available = 'available.svg';
      const occupied = 'occupied.svg';
      const faulted = 'faulted.svg';

      const availableOccupied = 'available-occupied.svg';
      const availableFaulted = 'available-faulted.svg';
      const occupiedFaulted = 'occupied-faulted.svg';

      if (!connection) {
        return offline;
      }

      const prefix = availability.indexOf('private') !== -1 ? sPrivate : sPublic;

      if (status.length === 1) {
        switch (status[0]) {
          case 'available':
            return prefix + available;
          case 'reserved':
          case 'occupied':
            return prefix + occupied;
          default:
            return prefix + faulted;
        }
      }

      const hasAvailable = status.indexOf('available') !== -1;
      const hasOccupied = status.indexOf('reserved') !== -1 || status.indexOf('occupied') !== -1;

      if (hasAvailable && hasOccupied) {
        return prefix + availableOccupied;
      }

      if (hasAvailable) {
        return prefix + availableFaulted;
      }

      return prefix + occupiedFaulted;
    },
    assignHubjectPin: function (markerData) {
      if (markerData === 1) {
        return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzEiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAzMSA0MCI+CiAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImNsaXAtcGF0aCI+CiAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfNTYwOSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNTYwOSIgd2lkdGg9IjMxIiBoZWlnaHQ9IjQwIiBmaWxsPSIjZmZmIi8+CiAgICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KICA8ZyBpZD0iR3JvdXBfMTA0NDciIGRhdGEtbmFtZT0iR3JvdXAgMTA0NDciIGNsaXAtcGF0aD0idXJsKCNjbGlwLXBhdGgpIj4KICAgIDxnIGlkPSJHcm91cF8xMDQ0NiIgZGF0YS1uYW1lPSJHcm91cCAxMDQ0NiI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzQ5MTkiIGRhdGEtbmFtZT0iUGF0aCA0OTE5IiBkPSJNMjYuMyw0LjNBMTUuMjczLDE1LjI3MywwLDAsMCwxNS41LDAsMTUuMzc0LDE1LjM3NCwwLDAsMCwwLDE1LjljMCwzLjIsMS4zLDYuNywzLjgsMTAuNnM2LjUsOC4xLDExLjcsMTIuNmM1LjItNC41LDkuMS04LjcsMTEuNi0xMi42LDIuNi0zLjksMy45LTcuNCwzLjktMTAuNkExNS4yMTQsMTUuMjE0LDAsMCwwLDI2LjMsNC4zWiIgZmlsbD0iIzAwMzNGRiIvPgogICAgICA8cGF0aCBpZD0iUGF0aF80OTIwIiBkYXRhLW5hbWU9IlBhdGggNDkyMCIgZD0iTTE1LjUsMzUuNUE1NS41NjUsNTUuNTY1LDAsMCwxLDYsMjQuN2MtMi4yLTMuNC0zLjMtNi4zLTMuMy04LjhBMTIuNzMyLDEyLjczMiwwLDAsMSw2LjQsNi40YTEyLjI5MywxMi4yOTMsMCwwLDEsOS4xLTMuNywxMi4xMzksMTIuMTM5LDAsMCwxLDkuMSwzLjcsMTIuNzMyLDEyLjczMiwwLDAsMSwzLjcsOS41YzAsMi41LTEuMSw1LjQtMy4yLDguOEE1Ni40NTcsNTYuNDU3LDAsMCwxLDE1LjUsMzUuNVoiIGZpbGw9IiMzYzAiLz4KICAgICAgPHBhdGggaWQ9IlBhdGhfNDkyMSIgZGF0YS1uYW1lPSJQYXRoIDQ5MjEiIGQ9Ik0xNS41LDM1LjVWMi43YTEyLjEzOSwxMi4xMzksMCwwLDEsOS4xLDMuNywxMi43MzIsMTIuNzMyLDAsMCwxLDMuNyw5LjVjMCwyLjUtMS4xLDUuNC0zLjIsOC44QTU2LjQ1Nyw1Ni40NTcsMCwwLDEsMTUuNSwzNS41WiIgZmlsbD0ib3JhbmdlIi8+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzQ5MjIiIGRhdGEtbmFtZT0iUGF0aCA0OTIyIiBkPSJNMTUuNDksMTYuMUgyMS41TDE1LjQ5LDI4LjMzN3YtOC4yTDkuNCwyMC4yLDE1LjQ5LDcuOVoiIGZpbGw9IiNmZmYiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=';
      } else if (markerData === 2) {
        return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzEiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAzMSA0MCI+CiAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImNsaXAtcGF0aCI+CiAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfNTYwOSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNTYwOSIgd2lkdGg9IjMxIiBoZWlnaHQ9IjQwIiBmaWxsPSIjZmZmIi8+CiAgICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KICA8ZyBpZD0iR3JvdXBfMTA0NDciIGRhdGEtbmFtZT0iR3JvdXAgMTA0NDciIGNsaXAtcGF0aD0idXJsKCNjbGlwLXBhdGgpIj4KICAgIDxnIGlkPSJHcm91cF8xMDQ0NiIgZGF0YS1uYW1lPSJHcm91cCAxMDQ0NiI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzQ5MTkiIGRhdGEtbmFtZT0iUGF0aCA0OTE5IiBkPSJNMjYuMyw0LjNBMTUuMjczLDE1LjI3MywwLDAsMCwxNS41LDAsMTUuMzc0LDE1LjM3NCwwLDAsMCwwLDE1LjljMCwzLjIsMS4zLDYuNywzLjgsMTAuNnM2LjUsOC4xLDExLjcsMTIuNmM1LjItNC41LDkuMS04LjcsMTEuNi0xMi42LDIuNi0zLjksMy45LTcuNCwzLjktMTAuNkExNS4yMTQsMTUuMjE0LDAsMCwwLDI2LjMsNC4zWiIgZmlsbD0iIzAwMzNGRiIvPgogICAgICA8cGF0aCBpZD0iUGF0aF80OTIwIiBkYXRhLW5hbWU9IlBhdGggNDkyMCIgZD0iTTE1LjUsMzUuNUE1NS41NjUsNTUuNTY1LDAsMCwxLDYsMjQuN2MtMi4yLTMuNC0zLjMtNi4zLTMuMy04LjhBMTIuNzMyLDEyLjczMiwwLDAsMSw2LjQsNi40YTEyLjI5MywxMi4yOTMsMCwwLDEsOS4xLTMuNywxMi4xMzksMTIuMTM5LDAsMCwxLDkuMSwzLjcsMTIuNzMyLDEyLjczMiwwLDAsMSwzLjcsOS41YzAsMi41LTEuMSw1LjQtMy4yLDguOEE1Ni40NTcsNTYuNDU3LDAsMCwxLDE1LjUsMzUuNVoiIGZpbGw9IiMzYzAiLz4KICAgICAgPHBhdGggaWQ9IlBhdGhfNDkyMSIgZGF0YS1uYW1lPSJQYXRoIDQ5MjEiIGQ9Ik0xNS41LDM1LjVWMi43YTEyLjEzOSwxMi4xMzksMCwwLDEsOS4xLDMuNywxMi43MzIsMTIuNzMyLDAsMCwxLDMuNyw5LjVjMCwyLjUtMS4xLDUuNC0zLjIsOC44QTU2LjQ1Nyw1Ni40NTcsMCwwLDEsMTUuNSwzNS41WiIgZmlsbD0iI0ZGMzMwMCIvPgogICAgICA8cGF0aCBpZD0iUGF0aF80OTIyIiBkYXRhLW5hbWU9IlBhdGggNDkyMiIgZD0iTTE1LjQ5LDE2LjFIMjEuNUwxNS40OSwyOC4zMzd2LTguMkw5LjQsMjAuMiwxNS40OSw3LjlaIiBmaWxsPSIjZmZmIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K';
      } else if (markerData === 3) {
        return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzEiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAzMSA0MCI+CiAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImNsaXAtcGF0aCI+CiAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfNTYwOSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNTYwOSIgd2lkdGg9IjMxIiBoZWlnaHQ9IjQwIiBmaWxsPSIjZmZmIi8+CiAgICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KICA8ZyBpZD0iR3JvdXBfMTA0NDciIGRhdGEtbmFtZT0iR3JvdXAgMTA0NDciIGNsaXAtcGF0aD0idXJsKCNjbGlwLXBhdGgpIj4KICAgIDxnIGlkPSJHcm91cF8xMDQ0NiIgZGF0YS1uYW1lPSJHcm91cCAxMDQ0NiI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzQ5MTkiIGRhdGEtbmFtZT0iUGF0aCA0OTE5IiBkPSJNMjYuMyw0LjNBMTUuMjczLDE1LjI3MywwLDAsMCwxNS41LDAsMTUuMzc0LDE1LjM3NCwwLDAsMCwwLDE1LjljMCwzLjIsMS4zLDYuNywzLjgsMTAuNnM2LjUsOC4xLDExLjcsMTIuNmM1LjItNC41LDkuMS04LjcsMTEuNi0xMi42LDIuNi0zLjksMy45LTcuNCwzLjktMTAuNkExNS4yMTQsMTUuMjE0LDAsMCwwLDI2LjMsNC4zWiIgZmlsbD0iIzAwMzNGRiIvPgogICAgICA8cGF0aCBpZD0iUGF0aF80OTIwIiBkYXRhLW5hbWU9IlBhdGggNDkyMCIgZD0iTTE1LjUsMzUuNUE1NS41NjUsNTUuNTY1LDAsMCwxLDYsMjQuN2MtMi4yLTMuNC0zLjMtNi4zLTMuMy04LjhBMTIuNzMyLDEyLjczMiwwLDAsMSw2LjQsNi40YTEyLjI5MywxMi4yOTMsMCwwLDEsOS4xLTMuNywxMi4xMzksMTIuMTM5LDAsMCwxLDkuMSwzLjcsMTIuNzMyLDEyLjczMiwwLDAsMSwzLjcsOS41YzAsMi41LTEuMSw1LjQtMy4yLDguOEE1Ni40NTcsNTYuNDU3LDAsMCwxLDE1LjUsMzUuNVoiIGZpbGw9Im9yYW5nZSIvPgogICAgICA8cGF0aCBpZD0iUGF0aF80OTIxIiBkYXRhLW5hbWU9IlBhdGggNDkyMSIgZD0iTTE1LjUsMzUuNVYyLjdhMTIuMTM5LDEyLjEzOSwwLDAsMSw5LjEsMy43LDEyLjczMiwxMi43MzIsMCwwLDEsMy43LDkuNWMwLDIuNS0xLjEsNS40LTMuMiw4LjhBNTYuNDU3LDU2LjQ1NywwLDAsMSwxNS41LDM1LjVaIiBmaWxsPSIjRkYzMzAwIi8+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzQ5MjIiIGRhdGEtbmFtZT0iUGF0aCA0OTIyIiBkPSJNMTUuNDksMTYuMUgyMS41TDE1LjQ5LDI4LjMzN3YtOC4yTDkuNCwyMC4yLDE1LjQ5LDcuOVoiIGZpbGw9IiNmZmYiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=';
      } else if (markerData === 4) {
        return 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAzMSA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzI1MzNfNzk3NCkiPgo8cGF0aCBkPSJNMjYuMyA0LjNDMjMuMiAxLjQgMTkuNiAwIDE1LjUgMEMxMS40IDAgNy44IDEuNCA0LjcgNC4zQzEuNiA3LjIgMCAxMS4xIDAgMTUuOUMwIDE5LjEgMS4zIDIyLjYgMy44IDI2LjVDNi4zIDMwLjQgMTAuMyAzNC42IDE1LjUgMzkuMUMyMC43IDM0LjYgMjQuNiAzMC40IDI3LjEgMjYuNUMyOS43IDIyLjYgMzEgMTkuMSAzMSAxNS45QzMxIDExLjEgMjkuNCA3LjIgMjYuMyA0LjNaIiBmaWxsPSIjMDAwMENDIi8+CjxwYXRoIGQ9Ik0xNS41IDM1LjVDMTEuMyAzMS43IDguMiAyOC4xIDYgMjQuN0MzLjggMjEuMyAyLjcgMTguNCAyLjcgMTUuOUMyLjcgMTIgMy45IDguOSA2LjQgNi40QzguOSAzLjkgMTEuOSAyLjcgMTUuNSAyLjdDMTkuMSAyLjcgMjIuMiAzLjkgMjQuNiA2LjRDMjcuMSA4LjkgMjguMyAxMiAyOC4zIDE1LjlDMjguMyAxOC40IDI3LjIgMjEuMyAyNS4xIDI0LjdDMjIuOSAyOC4xIDE5LjcgMzEuNyAxNS41IDM1LjVaIiBmaWxsPSIjMzNDQzAwIi8+CjxwYXRoIGQ9Ik0xNi4xIDE2LjFIMjEuNUwxNS4xIDI4LjRWMjAuMkg5LjRMMTYuMSA3LjlWMTYuMVoiIGZpbGw9IndoaXRlIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjUzM183OTc0Ij4KPHJlY3Qgd2lkdGg9IjMxIiBoZWlnaHQ9IjM5LjEiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==';
      } else if (markerData === 5) {
        return 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAzMSA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzI1MzNfNzk3NCkiPgo8cGF0aCBkPSJNMjYuMyA0LjNDMjMuMiAxLjQgMTkuNiAwIDE1LjUgMEMxMS40IDAgNy44IDEuNCA0LjcgNC4zQzEuNiA3LjIgMCAxMS4xIDAgMTUuOUMwIDE5LjEgMS4zIDIyLjYgMy44IDI2LjVDNi4zIDMwLjQgMTAuMyAzNC42IDE1LjUgMzkuMUMyMC43IDM0LjYgMjQuNiAzMC40IDI3LjEgMjYuNUMyOS43IDIyLjYgMzEgMTkuMSAzMSAxNS45QzMxIDExLjEgMjkuNCA3LjIgMjYuMyA0LjNaIiBmaWxsPSIjMDAwMENDIi8+CjxwYXRoIGQ9Ik0xNS41IDM1LjVDMTEuMyAzMS43IDguMiAyOC4xIDYgMjQuN0MzLjggMjEuMyAyLjcgMTguNCAyLjcgMTUuOUMyLjcgMTIgMy45IDguOSA2LjQgNi40QzguOSAzLjkgMTEuOSAyLjcgMTUuNSAyLjdDMTkuMSAyLjcgMjIuMiAzLjkgMjQuNiA2LjRDMjcuMSA4LjkgMjguMyAxMiAyOC4zIDE1LjlDMjguMyAxOC40IDI3LjIgMjEuMyAyNS4xIDI0LjdDMjIuOSAyOC4xIDE5LjcgMzEuNyAxNS41IDM1LjVaIiBmaWxsPSJvcmFuZ2UiLz4KPHBhdGggZD0iTTE2LjEgMTYuMUgyMS41TDE1LjEgMjguNFYyMC4ySDkuNEwxNi4xIDcuOVYxNi4xWiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yNTMzXzc5NzQiPgo8cmVjdCB3aWR0aD0iMzEiIGhlaWdodD0iMzkuMSIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K';
      } else if (markerData === 6) {
        return 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAzMSA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzI1MzNfNzk3NCkiPgo8cGF0aCBkPSJNMjYuMyA0LjNDMjMuMiAxLjQgMTkuNiAwIDE1LjUgMEMxMS40IDAgNy44IDEuNCA0LjcgNC4zQzEuNiA3LjIgMCAxMS4xIDAgMTUuOUMwIDE5LjEgMS4zIDIyLjYgMy44IDI2LjVDNi4zIDMwLjQgMTAuMyAzNC42IDE1LjUgMzkuMUMyMC43IDM0LjYgMjQuNiAzMC40IDI3LjEgMjYuNUMyOS43IDIyLjYgMzEgMTkuMSAzMSAxNS45QzMxIDExLjEgMjkuNCA3LjIgMjYuMyA0LjNaIiBmaWxsPSIjMDAwMENDIi8+CjxwYXRoIGQ9Ik0xNS41IDM1LjVDMTEuMyAzMS43IDguMiAyOC4xIDYgMjQuN0MzLjggMjEuMyAyLjcgMTguNCAyLjcgMTUuOUMyLjcgMTIgMy45IDguOSA2LjQgNi40QzguOSAzLjkgMTEuOSAyLjcgMTUuNSAyLjdDMTkuMSAyLjcgMjIuMiAzLjkgMjQuNiA2LjRDMjcuMSA4LjkgMjguMyAxMiAyOC4zIDE1LjlDMjguMyAxOC40IDI3LjIgMjEuMyAyNS4xIDI0LjdDMjIuOSAyOC4xIDE5LjcgMzEuNyAxNS41IDM1LjVaIiBmaWxsPSIjRkYzMzAwIi8+CjxwYXRoIGQ9Ik0xNi4xIDE2LjFIMjEuNUwxNS4xIDI4LjRWMjAuMkg5LjRMMTYuMSA3LjlWMTYuMVoiIGZpbGw9IndoaXRlIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjUzM183OTc0Ij4KPHJlY3Qgd2lkdGg9IjMxIiBoZWlnaHQ9IjM5LjEiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==';
      }

      return 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCAzMSA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzI1MzNfNzk3NCkiPgo8cGF0aCBkPSJNMjYuMyA0LjNDMjMuMiAxLjQgMTkuNiAwIDE1LjUgMEMxMS40IDAgNy44IDEuNCA0LjcgNC4zQzEuNiA3LjIgMCAxMS4xIDAgMTUuOUMwIDE5LjEgMS4zIDIyLjYgMy44IDI2LjVDNi4zIDMwLjQgMTAuMyAzNC42IDE1LjUgMzkuMUMyMC43IDM0LjYgMjQuNiAzMC40IDI3LjEgMjYuNUMyOS43IDIyLjYgMzEgMTkuMSAzMSAxNS45QzMxIDExLjEgMjkuNCA3LjIgMjYuMyA0LjNaIiBmaWxsPSIjMDAwMENDIi8+CjxwYXRoIGQ9Ik0xNS41IDM1LjVDMTEuMyAzMS43IDguMiAyOC4xIDYgMjQuN0MzLjggMjEuMyAyLjcgMTguNCAyLjcgMTUuOUMyLjcgMTIgMy45IDguOSA2LjQgNi40QzguOSAzLjkgMTEuOSAyLjcgMTUuNSAyLjdDMTkuMSAyLjcgMjIuMiAzLjkgMjQuNiA2LjRDMjcuMSA4LjkgMjguMyAxMiAyOC4zIDE1LjlDMjguMyAxOC40IDI3LjIgMjEuMyAyNS4xIDI0LjdDMjIuOSAyOC4xIDE5LjcgMzEuNyAxNS41IDM1LjVaIiBmaWxsPSIjQ0NDQ0NDIi8+CjxwYXRoIGQ9Ik0xNi4xIDE2LjFIMjEuNUwxNS4xIDI4LjRWMjAuMkg5LjRMMTYuMSA3LjlWMTYuMVoiIGZpbGw9IndoaXRlIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjUzM183OTc0Ij4KPHJlY3Qgd2lkdGg9IjMxIiBoZWlnaHQ9IjM5LjEiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==';
    },
    setMarkers: async function () {
      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

      this.newMarkers.forEach((location) => {
        const image = document.createElement('img');
        image.src = this.assignPin(location.final_status, location.availability, location.is_connected);

        const marker = new AdvancedMarkerElement({
          position: {
            lat: parseFloat(location.calculated_latitude),
            lng: parseFloat(location.calculated_longitude),
          },
          map: mapInstance,
          content: image,
          title: '' + location.id,
        });

        location.goToDashboard =
          this.canSeeChargePoints &&
          +this.userEmpId === +location.energy_mobility_provider_id &&
          (!this.userCpoId || +this.userCpoId === +location.charge_point_operator_id);

        this.markers[location.id] = { marker, location };

        marker.addListener('click', async () => {
          if (this.canSeeChargePoints) {
            window.location.href = '/admin/cp/dashboard/' + location.id;
          } else {
            this.infoWindow.setContent(this.localMarkerGetContent(location.id));
            this.infoWindow.open(null, marker);
          }
        });
      });
    },
    localMarkerGetContent: function (locationId) {
      const location = this.markers[locationId].location;
      let contentString =
        '<div id="content">' + '<h3 style="margin-bottom: 20px;">Charge Point Data</h3>' + '<div id="bodyContent">';

      for (const [index, evse_id] of location.evse_id.entries()) {
        contentString +=
          `<p style="margin-bottom: 0;"><b>Status:</b> ${location.final_status[index] ?? 'Status Not Available'}</p>` +
          `<p style="margin-bottom: 0;"><b>Evse ID:</b> ${evse_id}<img style="max-width: 20px; margin-left: 5px; cursor: pointer;"
                        onclick="navigator.clipboard.writeText('${evse_id}');"
                        src="/assets/images/copy.png" /></p>`;

        if (index < location.evse_id.length) {
          contentString += '<hr />';
        }
      }

      if (location.goToDashboard) {
        contentString += `<a href="/admin/cp/dashboard/${location.id}" style="margin-top: 10px;">Go to Dashboard</a>`;
      }

      contentString += '</div>' + '</div>';
      return contentString;
    },
    prepareHubjectMarkers: async function (hubjectMarkers) {
      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
      const chunkSize = 1000;
      const renderChunkSize = 20000;

      if (!clustererInstance) {
        clustererInstance = new MarkerClusterer({
          // map: mapInstance,
          algorithm: new SuperClusterViewportAlgorithm({
            radius: 200,
            viewportPadding: 200,
            maxZoom: 12,
          }),
        });
      }

      for (let j = 0; j < hubjectMarkers.length; j += renderChunkSize) {
        const renderChunk = hubjectMarkers.slice(j, j + renderChunkSize);
        const promises = [];
        for (let i = 0; i < renderChunk.length; i += chunkSize) {
          const chunk = renderChunk.slice(i, i + chunkSize);
          promises.push(
            new Promise((resolve) =>
              requestIdleCallback(
                () => {
                  const markers = [];

                  for (const markerData of chunk) {
                    if (this.geoHubjectMarkers[markerData.id]) {
                      continue;
                    }

                    this.geoHubjectMarkers[markerData.id] = true;

                    const position = {
                      lat: parseFloat(markerData.latitude),
                      lng: parseFloat(markerData.longitude),
                    };

                    const pinImage = document.createElement('img');

                    pinImage.src = this.assignHubjectPin(markerData.status);

                    let marker = new AdvancedMarkerElement({
                      position: position,
                      title: '' + markerData.id,
                      content: pinImage,
                    });

                    marker.addListener('click', async () => {
                      const data = await this.getHubjectDescription(markerData.id);
                      const description = data.description;
                      if (!description || !description.length) {
                        return;
                      }

                      this.infoWindow.setContent(
                        this.renderDescription(description[0].description, data['prices'], data['powers']),
                      );
                      this.infoWindow.open(null, marker);
                    });

                    markers.push(marker);
                    this.hubjectMarkers[position.lat + '_' + position.lng] = { marker, status: markerData.status };
                  }

                  clustererInstance.addMarkers(markers, true);
                  resolve();
                },
                {
                  timeout: 1000,
                },
              ),
            ),
          );
        }

        await Promise.all(promises);
        if (mapInstance) {
          clustererInstance.setMap(null);
          clustererInstance.setMap(mapInstance);
        }
      }
    },
    capitalizeString: function (string) {
      const words = string.split(' ');
      const capitalizedWords = [];
      for (const word of words) {
        capitalizedWords.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
      }

      return capitalizedWords.join(' ');
    },
    renderDescription: function (description, prices, powers) {
      let contentString =
        '<div id="content">' + '<h3 style="margin-bottom: 20px;">Charge Point Data</h3>' + '<div id="bodyContent">';

      let i = 0;
      const chargePoints = description.split(';');
      for (const chargePoint of chargePoints) {
        const chargePointData = chargePoint.split(',');
        contentString +=
          `<p style="margin-bottom: 0;"><b>Status:</b> ${chargePointData[1] ?? 'Status Not Available'}</p>` +
          `<p style="margin-bottom: 0;"><b>Evse ID:</b> ${chargePointData[0]}<img style="max-width: 20px; margin-left: 5px; cursor: pointer;"
                      onclick="navigator.clipboard.writeText('${chargePointData[0]}');"
                      src="/assets/images/copy.png" /></p>`;
        if (
          powers &&
          powers[chargePointData[0]] &&
          powers[chargePointData[0]]['charging_facilities'] &&
          powers[chargePointData[0]]['charging_facilities'][0]
        ) {
          contentString += `<p style="margin-bottom: 0;"><b>Power:</b> ${powers[chargePointData[0]]['charging_facilities'][0]['power']} kW</p>`;
        }

        if (prices && prices[chargePointData[0]] && prices[chargePointData[0]]['best_price']) {
          const bestPrice = prices[chargePointData[0]]['best_price'];
          contentString +=
            '<p style="margin-bottom: 2px;">---</p>' +
            `<p style="margin-bottom: 2px;"><b>Price:</b> ${bestPrice.price} (${bestPrice.reference_unit})</p>`;

          if (bestPrice.additional && bestPrice.additional.length) {
            for (const additionalElement of bestPrice.additional) {
              contentString += `<p style="margin-bottom: 2px;">
                <b>${this.capitalizeString(additionalElement.reference_type)}:</b>
                ${additionalElement.price_per_unit} (${additionalElement.reference_unit})</p>`;
            }
          }

          contentString += `<p style="margin-bottom: 2px;"><b>Currency:</b> ${bestPrice.currency}</p>`;
        }

        i++;
        if (i < chargePoints.length) {
          contentString += '<hr />';
        }
      }

      contentString += '</div>' + '</div>';
      return contentString;
    },
    initMap: async function () {
      const center = this.center.split('_');
      const home = { lat: +center[0], lng: +center[1] };

      const mapOptions = {
        center: home,
        zoom: this.zoom,
        mapId: this.mapId,
      };

      const { Map, InfoWindow } = await google.maps.importLibrary('maps');

      mapInstance = new Map(document.getElementById('map'), mapOptions);

      this.map = mapInstance;

      this.infoWindow = new InfoWindow({
        content: '',
      });
    },
    updateHubjectMarkers: function (hubjectMarkers) {
      for (const hubjectMarker of hubjectMarkers) {
        const key = hubjectMarker.latitude + '_' + hubjectMarker.longitude;
        if (this.hubjectMarkers[key] && this.hubjectMarkers[key]['status'] !== hubjectMarker.status) {
          this.hubjectMarkers[key]['marker'].content.src = this.assignHubjectPin(hubjectMarker.status);
          this.hubjectMarkers[key]['status'] = hubjectMarker.status;
        }
      }
    },
  },
  mounted: async function () {
    await this.initMap();
    let loaded = false;
    this.map.addListener('zoom_changed', () => {
      // this.processZoom();
    });
    this.map.addListener('idle', () => {
      if (!loaded) {
        loaded = true;
        // this.getGroupedData();
        this.getData();
        window.setInterval(this.getData, 10000);
        // window.setInterval(() => {
        //   this.getHubjectData(true, Object.keys(this.geoHubjectMarkers));
        // }, 60000);
        // this.getHubjectData();
      }
    });
  },
};
</script>
