<template>
  <div class="form-group row">
    <div class="col-2">
      <label class="mr-2 col-form-label">CP Id | Connector Id | CPO Name | EVSE</label>
      <input v-model="search" class="form-control" autofocus />
    </div>
    <div class="col-2">
      <label class="mr-2 col-form-label">Connection Status</label>
      <select v-model="connection" class="form-control">
        <option value="">Please select</option>
        <option value="1">Connected</option>
        <option value="2">Not connected</option>
      </select>
    </div>
    <div class="col-2">
      <label class="mr-2 col-form-label">Transaction Status</label>
      <select v-model="transaction" class="form-control">
        <option value="">Please select</option>
        <option value="1">In transaction</option>
        <option value="2">Not in transaction</option>
      </select>
    </div>
  </div>

  <hr />
  <div class="card mt-5 p-3">
    <div v-if="hasData">
      <div v-for="group in finalData">
        <div v-if="group.some((cp) => cp.charge_point_connector_pools.length)">
          <h3 class="text-center my-1">
            {{ group[0].charge_point_operator.company_connection.name }}
          </h3>
          <table class="table table-striped" style="font-size: 0.9em">
            <thead>
              <tr class="teal">
                <th scope="col">Charge Point ID</th>
                <th scope="col">Pool ID</th>
                <th scope="col">Name</th>
                <th scope="col">Evse ID</th>
                <th scope="col">Connectors</th>
                <th scope="col">Final status</th>
                <th scope="col">Connection status</th>
                <th scope="col">Availability status</th>
                <th scope="col">Manual status</th>
                <th scope="col">Transaction</th>
                <th scope="col">Error</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="chargePoint in group">
                <template v-for="pool in chargePoint.charge_point_connector_pools">
                  <tr>
                    <th scope="row" class="align-middle">
                      {{ pool.charge_point_id }}
                    </th>
                    <th class="align-middle">{{ pool.id }}</th>
                    <td class="align-middle">{{ pool.name }}</td>
                    <td class="align-middle">
                      {{ pool.evse_id }}
                    </td>
                    <td class="align-middle">
                      {{ pool.connectors.map((x) => x.id + ' (' + x.connector_id + ')').join(', ') }}
                    </td>
                    <td class="align-middle">
                      {{ pool.final_status }}
                    </td>
                    <td class="align-middle">
                      {{ chargePoint.is_connected ? 'Connected' : 'Not connected' }}
                    </td>
                    <td class="align-middle">
                      {{ pool.data.availability }}
                    </td>
                    <td class="align-middle">
                      {{ chargePoint.is_manual_off ? 'Off' : 'On' }}
                    </td>
                    <td class="align-middle">
                      {{
                        chargePoint.transactions_in_progress.some((x) =>
                          pool.connectors.some((y) => y.connector_id === x.connector_id),
                        )
                          ? 'Yes'
                          : ''
                      }}
                    </td>
                    <td class="align-middle">
                      {{ pool.error_code }}
                    </td>
                    <!--                                -->
                    <td class="p-2 align-middle">
                      <a
                        class="btn btn-teal-empty btn-sm mr-1"
                        style="margin-left: 0.5%"
                        :href="'/admin/cp/dashboard/' + chargePoint.id"
                      >
                        {{ dashboard }}
                      </a>
                      <a
                        class="btn btn-secondary btn-sm"
                        style="margin-left: 0.5%"
                        :href="'/admin/reports/transactions/' + chargePoint.id"
                      >
                        {{ reports }}
                      </a>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else-if="loading">Loading</div>
    <div v-else>No Data</div>
  </div>
</template>

<script>
export default {
  props: ['data', 'dashboard', 'reports'],
  data: function () {
    return {
      startData: [],
      search: '',
      connection: '',
      transaction: '',
      loading: true,
    };
  },
  methods: {
    sortData: function (a, b) {
      return a.length &&
        b.length &&
        a[0].charge_point_operator.company_connection.name < b[0].charge_point_operator.company_connection.name
        ? -1
        : 0;
    },
    sortChargers: function (a, b) {
      return a.id < b.id ? -1 : 0;
    },
    getData: async function () {
      return new Promise((resolve) => {
        axios
          .get('/admin/cp/get-statuses-data')
          .then((response) => {
            const data = response.data.data;
            this.startData = Object.values(data)
              .sort(this.sortData)
              .map((q) => q.sort(this.sortChargers));
            resolve();
          })
          .catch((error) => {
            alert(error);
            console.log(error);
            resolve();
          });
      });
    },
  },
  computed: {
    finalData: function () {
      if (!this.search && !this.connection && !this.transaction) {
        return this.startData;
      }

      const finalData = this.startData.map((q) => {
        return q
          .map((cp) => {
            return {
              ...cp,
              charge_point_connector_pools: cp.charge_point_connector_pools.filter((pool) => {
                const inTransaction = cp.transactions_in_progress.some((trans) =>
                  pool.connectors.some((conn) => conn.connector_id === trans.connector_id),
                );

                return (
                  (!this.connection ||
                    (+this.connection === 1 && cp.is_connected) ||
                    (+this.connection === 2 && !cp.is_connected)) &&
                  (!this.transaction ||
                    (+this.transaction === 1 && inTransaction) ||
                    (+this.transaction === 2 && !inTransaction)) &&
                  (cp.id === +this.search ||
                    cp.full_name.toLowerCase().includes(this.search.toLowerCase()) ||
                    cp.charge_point_operator.company_connection.name
                      .toLowerCase()
                      .includes(this.search.toLowerCase()) ||
                    (pool.evse_id && pool.evse_id.toLowerCase().includes(this.search.toLowerCase())) ||
                    pool.connectors.some((conn) => conn.id === +this.search))
                );
              }),
            };
          })
          .sort(this.sortChargers);
      });

      return finalData;
    },
    hasData: function () {
      return this.finalData.some((cps) => cps.some((cp) => cp.charge_point_connector_pools.length));
    },
  },
  async mounted() {
    window.setInterval(this.getData, 5000);
    await this.getData();
    this.loading = false;
  },
};
</script>
